/* Landing page component styles */
.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../assets/hero4.jpg') center/cover no-repeat; /* Background image for the hero section */
    height: 50vh; /* Adjust height as needed */
    position: relative;
  }
  
  .headshot {
    width: 300px; /* Set headshot image size */
    height: 300px;
    border-radius: 50%; /* Rounded shape for the headshot */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .hero-content {
    color:#ffffff;
    text-align: center;
    z-index: 1;
    height: 300px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .hero-content h1 {
    font-size: 36px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .hero-content p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .cta-button {
    padding: 10px 20px;
    background-color: #4b0082; /* Indigo button color */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #330066; /* Darker shade of indigo on hover */
  }

  .landing-content p{
    color: #ffffff;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 15px;
    width: 75vw;
    margin-left: auto;
    margin-right: auto;
  }