/* ProjectsPage.css */

.projects-page {
    /* padding: 20px; */
    color: #ffffff;
  }
  
  .project-card {
    border: 2px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 30px;
    width: 75vw;
    margin-right: auto;
    margin-left: auto;
    background-color: rgba(75, 0, 130, 0.8); /* Indigo with transparency */
    box-shadow: 0px 10px 15px -3px rgba(255,255,255,0.2);
  }
  
  .project-card h3 {
    font-size: 24px;
    margin-bottom: 5px;
  }

  .project-card h4 {
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 25px;
    margin-top: 5px;
    color: #f5f5f5;
  }
  
  .project-card p {
    margin-bottom: 10px;
    line-height: 1.5;
  }

  .project-card li {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  /* Add more styles for project cards or list as needed */
  .project-card-technology li {
    background-color: #f5f5f5;
    border: none;
    color: #333333;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 16px;
  }

  