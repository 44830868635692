.mini-hero {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../assets/hero4.jpg') center/cover no-repeat; /* Background image for the hero section */
    height: 10vh; /* Adjust height as needed */
    position: relative;
    margin-bottom: 45px;
  }
  
  .mini-hero-content {
    color:#f5f5f5;
    text-align: center;
    z-index: 1;
  }
  
  .mini-hero-content h1 {
    font-size: 36px;
    margin-bottom: 10px;
    margin-top: 10px;
  }