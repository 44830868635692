/* ResumePage.css */

.resume-page {
  color: #ffffff;
}

.resume-content {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
}

.resume-section {
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
  align-items: flex-start;
}

.resume-section h3 {
  font-size: 24px;
 
  margin-left: 10px;
  border-bottom: 5px solid rgba(75, 0, 130);
  box-shadow: 5px 5px 10px -5px rgba(255,255,255,0.2);
  width: 200px;
  text-align: right;
}

.resume-section-content {
  /* margin-bottom: 20px;
  width: 50%; */
  margin-left: 40px;
  margin-right: 1em;
  max-width: 800px;
  width: 100%;
}

.resume-section-content h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

.resume-section-content p {
  margin-bottom: 5px;
}

/* Add more styles for other resume sections as needed */

.skills-item li {
  background-color: rgba(75, 0, 130, 0.8);;
  border: none;
  color: #ffffff;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}


.work-item p {
  font-size: 14px;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 5px;
  margin-top: 10px;
  margin-left: 10px;
  color: #f5f5f5;
}

.work-item li {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 5px;
}

.work-item {
  border: 2px solid #ccc;
  border-radius: 15px;
  margin-inline-start: 40px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  background-color: rgba(75, 0, 130, 0.8); /* Indigo with transparency */
  box-shadow: 0px 10px 15px -3px rgba(255,255,255,0.2);
  max-width: 725px;
}

.resume-content-item h4 {
  font-size: 16px;
  margin-bottom: 5px;
}

.contact {
  text-align: right;
  line-height: .75;
}

.contact-bubble {
  background-color: rgba(75, 0, 130, 0.8);;
  border: none;
  color: #ffffff;
  padding: 5px 10px;

  text-decoration: none;
  /* display: inline-block; */
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
}

.contact-info.alt {
  align-items: center;
  margin-top: 80px;
  margin-bottom:55px;
  
}

.contact-info p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.contact-info button {
  background-color: rgba(75, 0, 130, 0.8);;
  border: none;
  color: #ffffff;
  padding: 5px 10px;

  text-decoration: none;
  /* display: inline-block; */
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 16px;
}

@media only screen and (max-width: 600px) {
  .resume-section {
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .resume-section-content {
    width: auto;
    display: none;
  }

  .visible .resume-section-content {
    display: block;
  }

  .contact {
    width: 100%;
    margin-left: auto;
    margin-right: 1em;
  }
  .contact p {
    margin-right: 1em;
  }

  .resume-section h3 {
    width: 100%;
    text-align: left;
  }
  .resume-section h3::before {
    content: "Show Section";
    font-size: x-small;
    float: right;
    margin: 10px;
    padding-right: 10px;
  }

  .visible h3::before {
    content: "Hide Selection";
  }

  .work-item {
    margin-inline-start: auto;
  }
}