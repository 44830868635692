/* Header component styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(75, 0, 130, 0.8); /* Indigo with transparency */
    color: #ffffff; /* White text color */
    padding: 20px;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .nav-links {
    list-style-type: none;
    display: flex;
    gap: 20px;
  }
  
  .nav-links li {
    padding: 0;
  }
  
  .header a {
    text-decoration: none;
    color: #ffffff;
  }
  
  